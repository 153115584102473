const state = {
  data: {},
};

const actions = {
  setUser({ commit }, payload) {
    commit("SET_USER", payload);
  },
};

const mutations = {
  SET_USER(state, response) {
    state.data = response;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
