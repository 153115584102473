import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import authentication from "@/plugins/authentication";
import "@/assets/style/main.scss";
import VueRouter from "vue-router";
import Mybimiboo from "@mybimiboo/uikit/main";
import VueToast from "vue-toast-notification";

Vue.use(VueRouter);
Vue.use(Mybimiboo);
Vue.use(authentication);
Vue.use(VueToast, { position: "top-right", duration: 2000 });

Vue.config.productionTip = false;

Vue.$keycloak.init({ onLoad: "login-required" }).then(async (auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    await Vue.$keycloak
      .loadUserInfo()
      .then((res) => store.dispatch("auth/setUser", res));

    new Vue({
      el: "#app",
      router,
      store,
      render: (h) => h(App, { props: { keycloak: Vue.$keycloak } }),
    });
  }

  setInterval(() => {
    Vue.$keycloak.updateToken(70);
  }, 6000);
});
