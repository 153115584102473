import Vue from "vue";
import axios from "axios";

export const API_PATH = process.env.VUE_APP_API_PATH
  ? process.env.VUE_APP_API_PATH
  : "https://mybimiboo.com/api";

axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;

const service = axios.create({
  baseURL: API_PATH,
  timeout: 60 * 4 * 1000,
  headers: {
    Accept: "application/json",
  },
});

service.interceptors.request.use(
  function (config) {
    const token = Vue.$keycloak.token;

    config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (data.code) {
      if (data.code === 401 || data.code === 403) {
        throw new Error(data.message);
      }

      return data;
    } else {
      return data;
    }
  },
  (error) => {
    return Promise.reject(error?.response?.data?.message || error.message);
  }
);
export default service;
