<template>
  <div class="user-block">
    <img class="user-block__img" src="@/assets/images/avatar.svg" />
    <span v-if="email" class="user-block__name">{{ email }}</span>
    <div class="user-block__logout-container">
      <div class="user-block__logout-block" @click="logout">
        <img src="@/assets/images/logout.svg" />
        <span class="user-block__logout-btn">Log out</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    email() {
      return this.$store.state.auth.data?.email;
    },
  },
  methods: {
    logout() {
      this.$keycloak.logout();
    },
  },
};
</script>

<style lang="scss">
.user-block {
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    .user-block__logout-container {
      display: block;
    }
  }
  &__name {
    margin-left: 15px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
      color: #7fcc4e;
    }
  }

  &__logout-container {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 999;
  }

  &__logout-block {
    background: #fff;
    border: 1px solid #e9ebec;
    border-radius: 20px;
    padding: 20px 30px;
    margin-top: 12px;
    width: 150px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      color: #7fcc4e;
    }
  }
}
</style>
