<template>
  <div class="loader">
    <div
      class="loader__loading"
      :style="{ width: width, height: height }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "72px",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
};
</script>

<style lang="scss">
.loader {
  text-align: center;
  width: 72px;
  height: 72px;
  &__loading {
    margin-bottom: 8px;
    background: url(~@/assets/images/loading.svg) no-repeat center;
    background-size: contain;
  }
}
</style>
