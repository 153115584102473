<template>
  <div id="app" class="page">
    <div v-if="loading" class="page__loading">
      <VLoader />
    </div>
    <template v-else>
      <header class="page__header">
        <div class="page__apps">
          <v-filter
            v-for="service in services"
            :key="service.id"
            :title="service.name"
            :type="service.id"
            :value="model"
            @change="onChangeApp"
          ></v-filter>
        </div>
        <UserBlock />
      </header>
      <iframe
        v-if="activeApp"
        :src="activeApp.url"
        frameborder="0"
        style="border: none; display: block"
        class="page__iframe"
      ></iframe>
    </template>
  </div>
</template>

<script>
import UserBlock from "@/components/UserBlock.vue";
import VLoader from "./components/VLoader.vue";

export default {
  components: {
    UserBlock,
    VLoader,
  },
  name: "app",
  data() {
    return {
      loading: false,
      model: null,
    };
  },
  computed: {
    services() {
      return this.$store.state.services.list;
    },
    activeApp() {
      return this.services.find((obj) => obj.id === this.model);
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch("services/getServices");
      if (this.$route?.query?.app) {
        this.model = this.$route?.query?.app;
      } else {
        this.services[0].id;
        this.onChangeApp(this.services[0].id);
      }
    } catch (e) {
      this.$toast.error(e, {
        duration: 0,
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    onChangeApp(value) {
      this.model = value;
      this.$router.replace({
        query: {
          app: value,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  &__apps {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__iframe {
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }
  &__loading {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
