import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import services from "./modules/services";
import loader from "./modules/loader";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { auth, services, loader },
});
