import { getServices } from "@/api/services";

const state = {
  list: [],
};

const mutations = {
  SET_SERVICES(state, list = []) {
    state.list = list;
  },
};

const actions = {
  async getServices({ commit }) {
    try {
      const { data } = await getServices();
      commit("SET_SERVICES", data || []);
    } catch (e) {
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
